const About = () => (
  <main className="p-4 mt-5">
    <div className="container mx-auto">
      <div>
        <h1 className="text-3xl font-bold mb-4">About Us</h1>
        <p className="text-gray-700">Welcome to EpicGamerz.Top!</p>
        <p>
          At EpicGamerz.Top, our passion for gaming drives everything we do. We
          are committed to bringing you the best gaming experience by providing
          a curated selection of top-quality games, insightful reviews, and the
          latest news from the gaming world.
        </p>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Who We Are</h2>
        <p>
          We are a team of dedicated gamers, developers, and writers who share a
          love for all things gaming. Our diverse backgrounds and expertise
          allow us to create a platform that caters to gamers of all levels,
          from casual players to hardcore enthusiasts.
        </p>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">What We Offer</h2>
        <ul class="list-disc list-inside	">
          <li>
            Curated Game Selection: Our team handpicks the best games across
            various genres to ensure you always have something exciting to play.
          </li>
          <li>
            In-Depth Reviews: Get detailed reviews and analyses of the latest
            games, helping you make informed decisions about your next gaming
            adventure.
          </li>
          <li>
            Latest News: Stay up-to-date with the most recent developments,
            announcements, and trends in the gaming industry.
          </li>
          <li>
            Community Engagement: Join a vibrant community of gamers where you
            can share your experiences, tips, and connect with fellow players.
          </li>
        </ul>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Our Vision</h2>
        <p>
          Our vision at EpicGamerz.Top is to become the ultimate destination for
          gamers worldwide. We aim to create a platform that not only entertains
          but also informs and connects gamers, fostering a strong and inclusive
          community.
        </p>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Our Commitment</h2>
        <ul class="list-disc list-inside	">
          <li>
            Quality: We strive to provide top-notch content and a seamless
            gaming experience.
          </li>
          <li>
            Integrity: We are dedicated to honesty and transparency in our
            reviews and content.
          </li>
          <li>
            Community: We value our community and are committed to creating a
            welcoming environment for all gamers.
          </li>
        </ul>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Get In Touch</h2>
        <p>
          We love hearing from our community! If you have any questions,
          feedback, or just want to chat about gaming, feel free to reach out to
          us at contact@epicgamerz.top. Thank you for visiting EpicGamerz.Top.
          Let’s embark on epic gaming adventures together!
        </p>
      </div>
    </div>
  </main>
);
export default About;
