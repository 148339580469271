const TermAndCondition = () => (
  <main className="p-4 my-10">
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="text-gray-700">Last Updated: 20 July 2024</p>
      <p>
        Welcome to EpicGamerz.Top! These terms and conditions outline the rules
        and regulations for the use of our website and services. By accessing
        this website, we assume you accept these terms and conditions in full.
        Do not continue to use EpicGamerz.Top if you do not accept all of the
        terms and conditions stated on this page.
      </p>
      <div className="pt-4">
        <ol class="list-decimal list-inside">
          <li className="font-bold">Use of the Website</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              Eligibility: By using our site, you affirm that you are at least
              13 years old. If you are under 13, you must have parental
              permission to use our services.
            </li>
            <li>
              License: Unless otherwise stated, EpicGamerz.Top and/or its
              licensors own the intellectual property rights for all material on
              EpicGamerz.Top. All intellectual property rights are reserved. You
              may view and/or print pages from EpicGamerz.Top for your own
              personal use, subject to restrictions set in these terms and
              conditions.
            </li>
            <li>Prohibited Uses: You are prohibited from:</li>
            <li>Republishing material from EpicGamerz.Top.</li>
            <li>
              Selling, renting, or sub-licensing material from EpicGamerz.Top.
            </li>
            <li>
              Reproducing, duplicating, or copying material from EpicGamerz.Top.
            </li>
            <li>
              Redistributing content from EpicGamerz.Top (unless content is
              specifically made for redistribution).
            </li>
          </ul>
          <li className="font-bold pt-2">User Content</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              Responsibility: You are responsible for any content you upload,
              post, or otherwise make available on EpicGamerz.Top.
            </li>
            <li>
              Rights: By posting content on EpicGamerz.Top, you grant us a
              non-exclusive, worldwide, royalty-free, and sublicensable right to
              use, reproduce, modify, adapt, publish, translate, create
              derivative works from, distribute, and display such content.
            </li>
            <li>Prohibited Content: You agree not to post content that:</li>
            <li>
              Is unlawful, harmful, threatening, abusive, harassing, defamatory,
              vulgar, obscene, invasive of another’s privacy, or otherwise
              objectionable.
            </li>
            <li>
              Violates any intellectual property or other proprietary rights of
              any party.
            </li>
          </ul>

          <li className="font-bold pt-2">Disclaimers</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              No Warranties: EpicGamerz.Top is provided "as is" without any
              representations or warranties, express or implied. EpicGamerz.Top
              makes no representations or warranties in relation to this website
              or the information and materials provided on this website.
            </li>
            <li>
              Limitations of Liability: In no event shall EpicGamerz.Top, nor
              any of its officers, directors, and employees, be liable to you
              for anything arising out of or in any way connected with your use
              of this website, whether such liability is under contract, tort,
              or otherwise. EpicGamerz.Top, including its officers, directors,
              and employees, shall not be liable for any indirect,
              consequential, or special liability arising out of or in any way
              related to your use of this website.
            </li>
          </ul>

          <li className="font-bold pt-2">Indemnification</li>
          <p>
            You hereby indemnify to the fullest extent EpicGamerz.Top from and
            against any and all liabilities, costs, demands, causes of action,
            damages, and expenses (including reasonable attorney's fees) arising
            out of or in any way related to your breach of any of the provisions
            of these terms.
          </p>
          <li className="font-bold pt-2">
            Modifications to the Service and Prices
          </li>
          <ul class="list-disc list-inside pl-4">
            <li>
              Service Changes: We reserve the right to modify or discontinue the
              service (or any part or content thereof) without notice at any
              time.
            </li>
            <li>
              Price Changes: Prices for our games and services are subject to
              change without notice.
            </li>
          </ul>
          <li className="font-bold pt-2">Governing Law</li>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of Surat, Gujarat, and you submit to the
            non-exclusive jurisdiction of the state and federal courts located
            in Surat, Gujarat for the resolution of any disputes.
          </p>
          <li className="font-bold pt-2">
            Changes to These Terms and Conditions
          </li>
          <p>
            We reserve the right to amend these terms and conditions at any
            time. Any changes will be posted on this page with an updated
            revision date. Your continued use of the website following the
            posting of changes constitutes your acceptance of such changes.
          </p>
          <li className="font-bold pt-2">Contact Information</li>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at contact@epicgamerz.top
          </p>
        </ol>
      </div>
    </div>
  </main>
);

export default TermAndCondition;
