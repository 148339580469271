import React from "react";

const Loader = () => (
  <div className="flex items-center justify-center h-full">
    <div className="relative flex items-center justify-center">
      <div className="w-12 h-12 bg-blue-500 rounded-full absolute animate-ping"></div>
      <div className="w-12 h-12 bg-blue-500 rounded-full animate-bounce"></div>
    </div>
  </div>
);

export default Loader;
