const PrivacyPolicy = () => (
  <main className="p-4 my-10">
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-700">Last Updated: 20 July 2024</p>
      <p>
        At EpicGamerz.Top, we are committed to protecting your privacy. This
        Privacy Policy outlines the types of information we collect, how we use
        it, and the steps we take to ensure it remains secure.
      </p>
      <div className="pt-4">
        <ol class="list-decimal list-inside">
          <li className="font-bold">Information We Collect</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              Personal Information: When you interact with our site, you may
              voluntarily provide us with personal information such as your name
              and email address. This typically occurs when you contact us,
              subscribe to our newsletter, or participate in community forums.
            </li>
            <li>
              Non-Personal Information: We automatically collect non-personal
              information, such as browser type, device information, IP address,
              and the pages you visit. This helps us understand how our site is
              used and improve your experience.
            </li>
          </ul>
          <li className="font-bold pt-2">How We Use Your Information</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              To Provide and Improve Our Services: We use your information to
              operate and enhance our website and the content we offer.
            </li>
            <li>
              Communication: If you provide us with your email address, we may
              send you updates, newsletters, and other communications. You can
              opt-out of these communications at any time.
            </li>
            <li>
              Analytics: We use non-personal information for analytical purposes
              to understand usage patterns and improve our services.
            </li>
            <li>
              Community Engagement: We may use your information to facilitate
              community interactions and ensure a positive user experience.
            </li>
          </ul>
          <li className="font-bold pt-2">Cookies and Tracking Technologies</li>
          <p>
            We use cookies and similar tracking technologies to enhance your
            experience on our website. Cookies are small data files stored on
            your device that help us remember your preferences and provide a
            personalized experience.
          </p>
          <ul class="list-disc list-inside pl-4">
            <li>Types of Cookies We Use:</li>
            <li>
              Essential Cookies: These are necessary for the website to function
              properly.
            </li>
            <li>
              Performance and Analytics Cookies: These help us understand how
              you use our site and improve its performance.
            </li>
            <li>
              Functional Cookies: These remember your preferences and settings.
            </li>
          </ul>
          <li className="font-bold pt-2">Sharing Your Information</li>
          <p>
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties. We may share non-personal
            information with third-party service providers to help us analyze
            and improve our site.
          </p>
          <li className="font-bold pt-2">Data Security</li>
          <p>
            We implement a variety of security measures to protect your personal
            information. While we strive to protect your information, we cannot
            guarantee its absolute security.
          </p>
          <li className="font-bold pt-2">Third-Party Links</li>
          <p>
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices of these sites, and we
            encourage you to review their privacy policies.
          </p>
          <li className="font-bold pt-2">Children's Privacy</li>
          <p>
            EpicGamerz.Top is not intended for children under the age of 13. We
            do not knowingly collect personal information from children under
            13. If we become aware that we have inadvertently collected such
            information, we will take steps to delete it.
          </p>
          <li className="font-bold pt-2">Changes to This Privacy Policy</li>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated revision date. We
            encourage you to review this Privacy Policy periodically.
          </p>
          <li className="font-bold pt-2">Contact Us</li>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at contact@epicgamerz.top.
          </p>
          <p>
            Thank you for visiting EpicGamerz.Top. We value your privacy and are
            committed to protecting your personal information.
          </p>
        </ol>
      </div>
    </div>
  </main>
);
export default PrivacyPolicy;
