import { NavLink } from "react-router-dom";
import logo from "../Assets/1 (1).png";

const Header = () => (
  <header className="bg-blue-500 text-white p-4">
    <nav className="container mx-auto flex justify-between">
      <img src={logo} alt="" className="w-[150px]" />
      <div className="flex items-center gap-6">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `font-bold text-lg ${isActive ? "text-blue-200" : "text-white"} 
            hover:border-b-2 hover:border-blue-300 transition-all duration-300`
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/privacy-policy"
          className={({ isActive }) =>
            `${isActive ? "text-blue-200" : "text-white"} 
              font-bold hover:border-b-2 hover:border-blue-300 transition-all duration-300`
          }
        >
          Privacy Policy
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            `${isActive ? "text-blue-200" : "text-white"} 
              font-bold hover:border-b-2 hover:border-blue-300 transition-all duration-300`
          }
        >
          About
        </NavLink>
        <NavLink
          to="/term&condition"
          className={({ isActive }) =>
            `${isActive ? "text-blue-200" : "text-white"} 
              font-bold hover:border-b-2 hover:border-blue-300 transition-all duration-300`
          }
        >
          Terms and Conditions
        </NavLink>
        <NavLink
          to="/contact-us"
          className={({ isActive }) =>
            `${isActive ? "text-blue-200" : "text-white"} 
              font-bold hover:border-b-2 hover:border-blue-300 transition-all duration-300`
          }
        >
          Contact Us
        </NavLink>
      </div>
    </nav>
  </header>
);

export default Header;
