import React, { useState, useEffect } from "react";
import Loader from "./Loader"; // Import your loader component
import Games from "../Const/Games";
import { Link } from "react-router-dom";

// Function to get random games
const getRandomGames = (games, count) => {
  const shuffled = [...games].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

// Function to truncate HTML content
const truncateHTML = (html, maxLength) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const text = doc.body.textContent || "";
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

const Home = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      const randomGames = getRandomGames(Games, 12);
      setGames(randomGames);
      setLoading(false);
    }, 800); // Simulate a delay for loading

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="p-8 bg-gray-100 min-h-screen flex items-center justify-center">
      {loading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-7xl">
          {games.map((blog, index) => (
            <div
              key={index}
              className="bg-white rounded-lg overflow-hidden shadow-lg transform transition duration-300 hover:scale-105"
            >
              <img
                src={blog.image}
                alt={`Blog ${index + 1}`}
                className="w-full h-64 object-cover"
              />
              <div className="p-6 pb-5">
                <h2 className="text-xl font-bold mb-2">{blog.title}</h2>
                <p className="text-gray-700 mb-4">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: truncateHTML(blog.description, 100),
                    }}
                  />
                </p>
                <Link
                  to={`/play?play=${blog?.gameurl}`}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full transition duration-300"
                >
                  Play
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default Home;
